import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Section from 'components/common/Section';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router';
import RedirectIfAuthenticated from '../../../middlewares/RedirectIfAuthenticated';

const AuthSimpleLayoutCiarus = () => {
  const navigate = useNavigate();
  useEffect(() => {
    RedirectIfAuthenticated(navigate);
  }, []);
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 pb-6 pt-0">
        <Col sm={10} md={8} lg={7} xl={7} className="col-xxl-4">
          <Card>
            <Card.Body className="p-4 p-sm-5">
              <Outlet />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default AuthSimpleLayoutCiarus;
