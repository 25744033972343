export const adminRoutes = {
  label: 'Administration',
  children: [
    {
      name: 'Clients',
      icon: 'shapes',
      to: '/administration/entreprises',
      active: true
    },
    {
      name: 'Administrateurs',
      icon: 'users',
      to: '/administration/admins',
      active: true
    }
  ]
};

export const customerRoutes = {
  label: 'Loadizi',
  children: [
    {
      name: 'Mes fichiers',
      icon: 'files',
      to: '/customers/dashboard',
      active: true
    }
  ]
};

export default [adminRoutes, customerRoutes];
