import React, { useState, useEffect } from 'react';
import { Offcanvas, Form, Button, Col, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import FormAlert from '../../commons/FormAlert';
import { PostUser, PutUser } from '../../../../services/UserService';
import SoftBadge from '../../../common/SoftBadge';

const ManageCompanyUsers = ({
  showManageUsers,
  setShowManageUsers,
  setparentTrigger,
  object
}) => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    active: 1
  });
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showManage, setShowManage] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (selectedUser && selectedUser.id) {
      setFormData({
        firstname: selectedUser.firstname,
        lastname: selectedUser.lastname,
        email: selectedUser.email,
        active: selectedUser.active
      });
    } else {
      setFormData({
        firstname: '',
        lastname: '',
        email: '',
        active: 1
      });
    }
  }, [selectedUser]);

  useEffect(() => {
    setUsers(object.users);
  }, [object]);

  const handleFieldChange = e => {
    if (e.target.name === 'active') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked ? 1 : 0
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    setIsLoading(true);
    let response = false;
    if (selectedUser && selectedUser.id) {
      response = await PutUser('admin.updateform', selectedUser.id, {
        ...formData,
        company_id: object.id,
        role_id: 2
      });
    } else
      response = await PostUser({
        ...formData,
        company_id: object.id,
        role_id: 2
      });
    setErrorForm(response.validator);
    setIsLoading(false);
    if (response.success) {
      setparentTrigger(Date.now());
      setShowManage(false);
      toast('Utilisateur enregistré');
    } else {
      toast('Une erreur est survenue');
    }
  };

  return (
    <>
      <Offcanvas
        placement={'end'}
        show={showManageUsers}
        onHide={() => {
          setShowManageUsers(false);
        }}
        style={{ width: '700px' }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Utilisateurs du client {object ? object.name : ''}{' '}
            <Button
              onClick={() => {
                setShowManage(true);
              }}
              variant="primary"
              size="sm"
              className="ms-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                style={{ marginBottom: '2px', marginRight: '2px' }}
              >
                <path
                  d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
              Ajouter
            </Button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Prénom</th>
                <th scope="col">Nom</th>
                <th scope="col">Email</th>
                <th scope="col">Actif ?</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {object &&
                users &&
                users.map((user, index) => (
                  <tr key={index}>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{user.email}</td>
                    <td>
                      {user.active ? (
                        <SoftBadge bg="success" className="me-2">
                          Oui
                        </SoftBadge>
                      ) : (
                        <SoftBadge bg="danger" className="me-2">
                          Non
                        </SoftBadge>
                      )}
                    </td>
                    <td>
                      <Button
                        onClick={() => {
                          setSelectedUser(user);
                          setShowManage(true);
                        }}
                        variant="primary"
                        size="sm"
                        className="ms-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="12"
                          height="12"
                        >
                          <path
                            d="M6.41421 15.89L16.5563 5.74786L15.1421 4.33365L5 14.4758V15.89H6.41421ZM7.24264 17.89H3V13.6474L14.435 2.21233C14.8256 1.8218 15.4587 1.8218 15.8492 2.21233L18.6777 5.04075C19.0682 5.43128 19.0682 6.06444 18.6777 6.45497L7.24264 17.89ZM3 19.89H21V21.89H3V19.89Z"
                            fill="rgba(255,255,255,1)"
                          ></path>
                        </svg>
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        placement={'end'}
        show={showManage}
        onHide={() => {
          setShowManage(false);
          setSelectedUser({});
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedUser && selectedUser.id
              ? "Modifier l'utilisateur"
              : 'Créer un utilisateur'}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form>
            <Form.Group controlId="lastname">
              <Form.Label>Nom :</Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                placeholder="Nom"
                value={formData.lastname || ''}
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group controlId="firstname" className="mt-3">
              <Form.Label>Prénom :</Form.Label>
              <Form.Control
                type="text"
                name="firstname"
                placeholder="Prénom"
                value={formData.firstname || ''}
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group controlId="email" className="mt-3">
              <Form.Label>Adresse email :</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email || ''}
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Col xs={12} className="mt-3">
              <Alert variant="info">
                <label>
                  <input
                    type="checkbox"
                    id="active"
                    name="active"
                    value="1"
                    className="me-1"
                    checked={formData.active === 1 || formData.active === '1'}
                    onChange={handleFieldChange}
                    style={{ position: 'relative', bottom: '-1px' }}
                  />
                  Compte actif ?
                </label>
                <span className="d-block smaller-p mb-0">
                  <small>
                    Cocher cette case permet à l'utilisateur de pouvoir se
                    connecter.
                  </small>
                </span>
              </Alert>
            </Col>

            <Button
              onClick={handleSubmit}
              size="sm"
              className="mt-3"
              disabled={isLoading}
            >
              Enregistrer
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ManageCompanyUsers;

ManageCompanyUsers.propTypes = {
  showManageUsers: PropTypes.bool,
  object: PropTypes.object,
  setShowManageUsers: PropTypes.func,
  setparentTrigger: PropTypes.func
};
