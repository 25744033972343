import React, { useContext, useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import logo from 'assets/img/logo-loadizi-light.png';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import routes from 'routes/routes';
import { capitalize } from 'helpers/utils';
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import bgNavbar from 'assets/img/generic/bg-navbar.png';
import { Link } from 'react-router-dom';

const NavbarVertical = () => {
  const [user, setUser] = useState({});
  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu
    }
  } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (localStorage.getItem('userLoadIzi')) {
      setUser(JSON.parse(localStorage.getItem('userLoadIzi')));
    }
    if (
      isNavbarVerticalCollapsed ||
      window.location.pathname === '/customers/checkout'
    ) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Fragment>
      {user.role_id && (
        <Navbar
          expand="xs"
          className={classNames('navbar-vertical', {
            [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
          })}
          variant="light"
        >
          <Flex alignItems="center">
            <ToggleButton />
            <Link
              to="#"
              className="text-decoration-none navbar-brand text-left"
            >
              <div className="d-flex align-items-center py-3">
                <img className="me-2" src={logo} alt="Logo" width={90} />
              </div>
            </Link>
          </Flex>
          <Navbar.Collapse
            in={showBurgerMenu}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              backgroundImage:
                navbarStyle === 'vibrant'
                  ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
                  : 'none'
            }}
          >
            <div className="navbar-vertical-content scrollbar">
              <Nav className="flex-column" as="ul">
                {user.role_id === 1 ? (
                  <Fragment>
                    {routes.map(route => (
                      <Fragment key={route.label}>
                        {!route.labelDisable && (
                          <NavbarLabel label={capitalize(route.label)} />
                        )}
                        <NavbarVerticalMenu routes={route.children} />
                      </Fragment>
                    ))}
                  </Fragment>
                ) : user.role_id === 2 ? (
                  <Fragment>
                    {routes
                      .filter(route => route.label === 'Loadizi')
                      .map(route => (
                        <Fragment key={route.label}>
                          {!route.labelDisable && (
                            <NavbarLabel label={capitalize(route.label)} />
                          )}
                          <NavbarVerticalMenu routes={route.children} />
                        </Fragment>
                      ))}
                  </Fragment>
                ) : (
                  ''
                )}
              </Nav>
              <>
                {navbarPosition === 'combo' && (
                  <div className={`d-${topNavbarBreakpoint}-none`}>
                    <div className="navbar-vertical-divider">
                      <hr className="navbar-vertical-hr my-2" />
                    </div>
                    <Nav navbar>
                      <NavbarTopDropDownMenus />
                    </Nav>
                  </div>
                )}
              </>
            </div>
          </Navbar.Collapse>
        </Navbar>
      )}
    </Fragment>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string
};

export default NavbarVertical;
