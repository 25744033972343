import { toast } from 'react-toastify';

export default function RequireAuthCustomer(navigate) {
  if (
    !localStorage.getItem('accessTokenLoadIzi') &&
    !localStorage.getItem('userLoadIzi')
  ) {
    toast('Veuillez vous connecter pour accéder au contenu');
    navigate('/login');
  } else if (JSON.parse(localStorage.getItem('userLoadIzi')).role_id !== 2) {
    toast("Vous n'êtes pas autorisé à accéder à cette page.");
    navigate('/');
  }
}
