import ModelService from './ModelService';

const modelService = new ModelService();

const model = 'companies';

export async function IndexCompany() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowCompany(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function getStripePortalUrl(id) {
  return modelService.ShowModel(`${model}/get-stripe-portal-url/${id}`);
}

export async function PostCompany(data) {
  return modelService.PostModel(`${model}`, data);
}

export async function PutCompany(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteCompany(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
