import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { LogOutUserService } from '../../../../services/AuthService';
const ProfileDropdown = () => {
  return (
    <Dropdown navbar={true} as="li" id="top-profile-dropdown">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#"
        className="pe-0 ps-2 nav-link"
      >
        {localStorage.getItem('userLoadIzi') &&
        JSON.parse(localStorage.getItem('userLoadIzi')).profile_picture_url !==
          undefined &&
        JSON.parse(localStorage.getItem('userLoadIzi')).profile_picture_url !==
          null ? (
          <Avatar
            src={
              JSON.parse(localStorage.getItem('userLoadIzi'))
                .profile_picture_url
            }
          />
        ) : localStorage.getItem('userLoadIzi') ? (
          <Avatar
            name={`${
              JSON.parse(localStorage.getItem('userLoadIzi')).firstname
            } ${JSON.parse(localStorage.getItem('userLoadIzi')).lastname}`}
          />
        ) : (
          ''
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {JSON.parse(localStorage.getItem('userLoadIzi'))
            .stripe_portal_url && (
            <Dropdown.Item
              as={Link}
              onClick={() => {
                window
                  .open(
                    'https://billing.stripe.com/p/login/aEU14j01P1Xo3G8aEE',
                    '_blank'
                  )
                  .focus();
              }}
              to="#"
            >
              Gérer mon abonnement
            </Dropdown.Item>
          )}
          <Dropdown.Item as={Link} to="https://hostay.fr/contact/">
            Besoin d'aide ?
          </Dropdown.Item>
          <Dropdown.Item as={Link} onClick={() => LogOutUserService()} to="#">
            Déconnexion
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
