import React, { useState, useEffect } from 'react';
import { Offcanvas, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PostCompany, PutCompany } from '../../../../services/CompanyService';
import { toast } from 'react-toastify';
import FormAlert from '../../commons/FormAlert';

const ManageCompany = ({
  showManageCompany,
  setShowManageCompany,
  setparentTrigger,
  object,
  setObject
}) => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    zip: '',
    city: '',
    country: 'France',
    tax_id: '',
    subscription_type: '',
    contact_email: ''
  });
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (object && object.id) {
      setFormData({
        name: object.name,
        address: object.address,
        zip: object.zip,
        city: object.city,
        country: object.country,
        tax_id: object.tax_id,
        subscription_type: object.subscription_type,
        contact_email: object.contact_email
      });
    } else {
      setFormData({
        name: '',
        address: '',
        zip: '',
        city: '',
        country: 'France',
        tax_id: '',
        subscription_type: '',
        contact_email: ''
      });
    }
  }, [object]);

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    setIsLoading(true);
    let response = false;
    if (object && object.id) {
      response = await PutCompany(object.id, {
        ...formData,
        subscription_type: formData.subscription_type
      });
    } else
      response = await PostCompany({
        ...formData,
        subscription_type: formData.subscription_type
      });
    setErrorForm(response.validator);
    setIsLoading(false);
    if (response.success) {
      setparentTrigger(Date.now);
      setShowManageCompany(false);
      toast(object ? 'Client modifié' : 'Client créé');
    } else {
      toast('Une erreur est survenue');
    }
  };

  return (
    <>
      <Offcanvas
        placement={'end'}
        show={showManageCompany}
        onHide={() => {
          setShowManageCompany(false);
          setObject({});
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {object && object.id ? 'Modifier le client' : 'Créer un client'}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Nom :</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Nom de la société"
                value={formData.name || ''}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="name" className="mt-3">
              <Form.Label>Adresse :</Form.Label>
              <Form.Control
                type="text"
                name="address"
                placeholder="Adresse"
                value={formData.address || ''}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="name" className="mt-3">
              <Form.Label>CP :</Form.Label>
              <Form.Control
                type="text"
                name="zip"
                placeholder="Code postal"
                value={formData.zip || ''}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="name" className="mt-3">
              <Form.Label>Ville :</Form.Label>
              <Form.Control
                type="text"
                name="city"
                placeholder="Ville"
                value={formData.city || ''}
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group controlId="name" className="mt-3">
              <Form.Label>Pays :</Form.Label>
              <Form.Control
                type="text"
                name="country"
                placeholder="Pays"
                value={formData.country || ''}
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group controlId="name" className="mt-3">
              <Form.Label>N° TVA :</Form.Label>
              <Form.Control
                type="text"
                name="tax_id"
                placeholder="Numéro TVA intra-communautaire"
                value={formData.tax_id || ''}
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group controlId="name" className="mt-3">
              <Form.Label>Plan :</Form.Label>
              <Form.Control
                type="text"
                name="subscription_type"
                placeholder="Free, Starter..."
                value={formData.subscription_type || ''}
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group controlId="name" className="mt-3">
              <Form.Label>
                Adresse email de contact{' '}
                <small>création de son compte Loadizi automatique</small> :
              </Form.Label>
              <Form.Control
                type="email"
                name="contact_email"
                placeholder="Email de contact"
                value={formData.contact_email || ''}
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Button
              onClick={handleSubmit}
              size="sm"
              className="mt-3"
              disabled={isLoading}
            >
              Enregistrer
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ManageCompany;

ManageCompany.propTypes = {
  showManageCompany: PropTypes.bool,
  object: PropTypes.object,
  setObjet: PropTypes.func,
  setShowManageCompany: PropTypes.func,
  setparentTrigger: PropTypes.func,
  setObject: PropTypes.func
};
