import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Offcanvas,
  OverlayTrigger,
  ProgressBar,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Moment from 'moment';
import PropTypes from 'prop-types';
import SoftBadge from '../../../common/SoftBadge';
import FormAlert from '../../commons/FormAlert';
import FileUploaderLoadizi from '../../commons/fileUploader';
import {
  DeleteFile,
  IndexFile,
  PutFile
} from '../../../../services/SharedFileService';
import Lottie from 'react-lottie-player';
import congratulation from 'assets/img/animated-icons/congratulation.json';
import { useNavigate } from 'react-router';

const IndexFiles = () => {
  const [trigger, setTrigger] = useState(Date.now());
  const [objects, setObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [errorFormEdit, setErrorFormEdit] = useState(false);
  const [formDataCreate, setFormDataCreate] = useState({
    file_url: '',
    file_size: ''
  });
  const [formDataEdit, setFormDataEdit] = useState({
    name: ''
  });
  const [limitStorage, setLimitStorage] = useState(0);
  const [sumStorage, setSumStorage] = useState(0);
  const [availableStorage, setAvailableStorage] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexFile();
      if (responseObjects.success === true) {
        setObjects(responseObjects.data.files);
        setLimitStorage(responseObjects.data.limitStorage);
        setSumStorage(responseObjects.data.sumStorage);
        setAvailableStorage(
          responseObjects.data.limitStorage - responseObjects.data.sumStorage
        );
      } else if (responseObjects.data === 'trial_ended') {
        toast(
          "Votre période d'essai est terminée. Souscrivez à une offre pour continuer."
        );
        navigate('/customers/checkout');
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObjects();
  }, [trigger]);

  const deleteObject = async () => {
    const response = await DeleteFile(selectedObject.id);
    if (response.success === true) {
      setShowDelete(false);
      setSelectedObject({});
      toast('Fichier supprimé !');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const editObject = async () => {
    setIsLoading(true);
    const response = await PutFile(selectedObject.id, formDataEdit);
    setErrorFormEdit(response.validator);
    if (response.success === true) {
      setShowEdit(false);
      setSelectedObject({});
      setErrorFormEdit(false);
      setIsLoading(false);
      toast('Fichier modifié !');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      {objects.length > 0 && (
        <Card>
          <Card.Body className="overflow-hidden px-lg-3">
            <Row className="justify-content-between align-items-center">
              <Col xs={7}>
                <h3 className="text-primary admin-title mb-0">Mes fichiers</h3>
                <div className="smaller-p mt-2 mb-2">
                  <small>
                    Espace disponible sur votre compte :{' '}
                    <SoftBadge
                      bg={availableStorage < 0 ? 'danger' : 'success'}
                      className="me-2"
                    >
                      {
                        // if file size is less than 1MB
                        availableStorage < 0
                          ? 'Espace saturé'
                          : availableStorage < 1000000
                          ? `${Math.round(availableStorage / 1000)} Ko` // if file size is less than 1GB
                          : availableStorage < 1000000000
                          ? `${Math.round(availableStorage / 1000000)} Mo` // if file size is less than 1TB
                          : `${Math.round(availableStorage / 1000000000)} Go`
                      }
                    </SoftBadge>
                    {JSON.parse(localStorage.getItem('userLoadIzi')).company
                      .subscription_type === 'trial' ? (
                      <a href="/customers/checkout" className="d-none">
                        🚀 Besoin de plus de stockage ?
                      </a>
                    ) : JSON.parse(localStorage.getItem('userLoadIzi')).company
                        ?.stripe_customer_id ? (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://billing.stripe.com/p/login/aEU14j01P1Xo3G8aEE"
                      >
                        🚀 Changer de plan
                      </a>
                    ) : (
                      ''
                    )}
                  </small>
                </div>
              </Col>
              <Col xs={5} className="text-end">
                <FileUploaderLoadizi
                  formData={formDataCreate}
                  setFormData={setFormDataCreate}
                  format="any"
                  databaseField="file_url"
                  cropperEnabled={false}
                  progressBar={true}
                  setTrigger={setTrigger}
                />
              </Col>
              <Col xs={3} className="mt-0">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="overlay-trigger-example">
                      Chargé :{' '}
                      {sumStorage < 1000000
                        ? `${Math.round(sumStorage / 1000)} Ko`
                        : sumStorage < 1000000000
                        ? `${Math.round(sumStorage / 1000000)} Mo`
                        : `${Math.round(sumStorage / 1000000000)} Go`}{' '}
                      sur{' '}
                      {limitStorage < 1000000
                        ? `${Math.round(limitStorage / 1000)} Ko`
                        : limitStorage < 1000000000
                        ? `${Math.round(limitStorage / 1000000)} Mo`
                        : `${Math.round(limitStorage / 1000000000)} Go`}
                    </Tooltip>
                  }
                >
                  <ProgressBar
                    now={
                      sumStorage > 0
                        ? (sumStorage / limitStorage) * 100 < 1
                          ? 1
                          : (sumStorage / limitStorage) * 100
                        : 0
                    }
                    style={{ height: '10px', cursor: 'default' }}
                  />
                </OverlayTrigger>
              </Col>
              {JSON.parse(localStorage.getItem('userLoadIzi')).company
                .subscription_type === 'trial' && (
                <Col xs={12} className="mt-1">
                  <small>
                    <SoftBadge bg="warning" className="me-2 mt-3">
                      Fin de l'essai gratuit dans{' '}
                      {14 -
                        Math.round(
                          (new Date().getTime() -
                            new Date(
                              JSON.parse(
                                localStorage.getItem('userLoadIzi')
                              ).company?.created_at
                            ).getTime()) /
                            (1000 * 3600 * 24)
                        )}{' '}
                      jours
                    </SoftBadge>
                  </small>
                  <small
                    className="pt-1"
                    style={{ position: 'relative', top: '1px' }}
                  >
                    <a href="/customers/checkout">Souscrire à un plan</a>
                  </small>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      )}
      {objects.length < 1 && (
        <Card className="mt-3">
          <Card.Body className="overflow-hidden px-lg-3 py-7">
            <Row className="justify-content-center align-items-center">
              <Col xs={12} className="text-center">
                <h4 className="">Bienvenue sur Loadizi !</h4>
                <Col cs={12}>
                  <small>
                    Espace disponible sur votre compte :{' '}
                    <SoftBadge
                      bg={availableStorage < 0 ? 'danger' : 'success'}
                      className="me-2"
                    >
                      {
                        // if file size is less than 1MB
                        availableStorage < 0
                          ? 'Espace saturé'
                          : availableStorage < 1000000
                          ? `${Math.round(availableStorage / 1000)} Ko` // if file size is less than 1GB
                          : availableStorage < 1000000000
                          ? `${Math.round(availableStorage / 1000000)} Mo` // if file size is less than 1TB
                          : `${Math.round(availableStorage / 1000000000)} Go`
                      }
                    </SoftBadge>
                    {JSON.parse(localStorage.getItem('userLoadIzi')).company
                      .subscription_type === 'trial' ? (
                      <a href="/customers/checkout" className="">
                        🚀 Besoin de plus de stockage ?
                      </a>
                    ) : JSON.parse(localStorage.getItem('userLoadIzi')).company
                        ?.stripe_customer_id ? (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://billing.stripe.com/p/login/aEU14j01P1Xo3G8aEE"
                      >
                        🚀 Changer de plan
                      </a>
                    ) : (
                      ''
                    )}
                  </small>
                </Col>
                <Lottie
                  loop
                  animationData={congratulation}
                  play
                  style={{
                    width: '300px',
                    height: '190px',
                    cursor: 'default',
                    textAlign: 'center',
                    margin: '0 auto'
                  }}
                />
                <h4 className="mt-3">
                  <small>Chargez votre premier fichier</small>
                </h4>
              </Col>
              <Col xs={5} className="text-center">
                <FileUploaderLoadizi
                  formData={formDataCreate}
                  setFormData={setFormDataCreate}
                  format="any"
                  databaseField="file_url"
                  cropperEnabled={false}
                  progressBar={true}
                  setTrigger={setTrigger}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
      {/*<Offcanvas
        show={showCreate}
        onHide={() => {
          setShowCreate(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Charger un fichier</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="gx-2 gy-3 mb-3">
            <Col xs={12}>
              {errorFormCreate && (
                <div className="mt-0">
                  <FormAlert variant="danger" data={errorFormCreate} />
                </div>
              )}
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="17"
                    height="17"
                    style={{ marginRight: '4px', marginBottom: '2px' }}
                  >
                    <path
                      d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                      fill="rgba(100,99,99,1)"
                    />
                  </svg>
                  Fichier :
                </Form.Label>
                <FileUploaderLoadizi
                  formData={formDataCreate}
                  setFormData={setFormDataCreate}
                  format="any"
                  databaseField="file_url"
                  cropperEnabled={false}
                  progressBar={true}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowCreate(false);
              setFormDataCreate({
                file_url: '',
                file_size: ''
              });
            }}
          >
            Annuler
          </Button>
          <Button
            size="sm"
            variant="primary"
            type="submit"
            onClick={e => {
              handleSubmitCreate(e);
            }}
            disabled={isLoading}
            className="me-2 admin-primary"
          >
            Envoyer
          </Button>
        </Offcanvas.Body>
      </Offcanvas>*/}
      <Offcanvas
        show={showEdit}
        onShow={() => {
          setFormDataEdit({
            name: selectedObject.name
          });
        }}
        onHide={() => {
          setShowEdit(false);
          setSelectedObject({});
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Modifier le nom</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={e => editObject(e)}>
            <Row className="gx-2 gy-3 mb-3">
              <Col xs={12}>
                {errorFormEdit && (
                  <div className="mt-0">
                    <FormAlert variant="danger" data={errorFormEdit} />
                  </div>
                )}
              </Col>
              <Col xs={12}>
                <Form.Group controlId="firstname">
                  <Form.Label>Nom du fichier :</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Nom du fichier"
                    value={formDataEdit.name || ''}
                    onChange={e => {
                      setFormDataEdit({
                        ...formDataEdit,
                        name: e.target.value
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              onClick={() => {
                setShowEdit(false);
                setSelectedObject({});
                setFormDataEdit({
                  name: ''
                });
              }}
            >
              Annuler
            </Button>
            <Button
              size="sm"
              variant="primary"
              type="submit"
              onClick={e => {
                editObject(e);
              }}
              disabled={isLoading}
              className="me-2 admin-primary"
            >
              Enregistrer
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      {objects.length > 0 && (
        <>
          <Offcanvas
            show={showDelete}
            onHide={() => {
              setShowDelete(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {selectedObject.id !== undefined && (
                  <>
                    La suppression du fichier "{selectedObject.name}" est
                    définitive.
                  </>
                )}
                <br />
                Voulez-vous continuer ?
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Button
                size="sm"
                variant="falcon-danger"
                className="me-2"
                onClick={() => {
                  deleteObject();
                }}
              >
                Oui, supprimer
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowDelete(false);
                }}
              >
                Annuler
              </Button>
            </Offcanvas.Body>
          </Offcanvas>
          <Card className="mt-3">
            <Card.Body>
              <div className="table-responsive scrollbar-visible table-container">
                <Table bordered responsive className="admin-table mt-2">
                  <thead>
                    <tr className="table-fixed-header">
                      <th scope="col">Fichier</th>
                      <th scope="col">Lien</th>
                      <th scope="col">Taille</th>
                      <th scope="col">Création</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {objects.map((object, index) => (
                      <tr key={index}>
                        <td>
                          {object.name}{' '}
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              setSelectedObject(object);
                              setShowEdit(true);
                            }}
                          >
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="overlay-trigger-example">
                                  Modifier le nom du fichier
                                </Tooltip>
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="12"
                                height="12"
                              >
                                <path
                                  d="M6.41421 15.89L16.5563 5.74786L15.1421 4.33365L5 14.4758V15.89H6.41421ZM7.24264 17.89H3V13.6474L14.435 2.21233C14.8256 1.8218 15.4587 1.8218 15.8492 2.21233L18.6777 5.04075C19.0682 5.43128 19.0682 6.06444 18.6777 6.45497L7.24264 17.89ZM3 19.89H21V21.89H3V19.89Z"
                                  fill="rgba(44,123,229,1)"
                                ></path>
                              </svg>
                            </OverlayTrigger>
                          </span>
                        </td>
                        <td>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={object.file_url}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="16"
                              height="16"
                              style={{
                                marginRight: '3px',
                                marginBottom: '3px',
                                marginLeft: '3px'
                              }}
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z"
                                fill="rgba(44,123,229,1)"
                              />
                            </svg>
                            {object.file_url.substring(
                              object.file_url.lastIndexOf('/') + 1
                            )}
                          </a>{' '}
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="overlay-trigger-example">
                                Copier le lien dans le presse-papier
                              </Tooltip>
                            }
                          >
                            <Button
                              size="sm"
                              variant="falcon-default"
                              className="me-0 px-1 ms-1"
                              type="button"
                              onClick={() => {
                                navigator.clipboard.writeText(object.file_url);
                                toast('Lien copié dans le presse-papier');
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="14"
                                height="14"
                                style={{
                                  marginBottom: '2px'
                                }}
                              >
                                <path
                                  d="M7 4V2H17V4H20.0066C20.5552 4 21 4.44495 21 4.9934V21.0066C21 21.5552 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5551 3 21.0066V4.9934C3 4.44476 3.44495 4 3.9934 4H7ZM7 6H5V20H19V6H17V8H7V6ZM9 4V6H15V4H9Z"
                                  fill="#000000"
                                />
                              </svg>
                            </Button>
                          </OverlayTrigger>
                        </td>
                        <td>
                          {
                            // if file size is less than 1MB
                            object.file_size < 1000000
                              ? `${Math.round(object.file_size / 1000)} Ko` // if file size is less than 1GB
                              : object.file_size < 1000000000
                              ? `${Math.round(object.file_size / 1000000)} Mo` // if file size is less than 1TB
                              : `${Math.round(
                                  object.file_size / 1000000000
                                )} Go`
                          }
                        </td>
                        <td className="">
                          {Moment(object.created_at)
                            .local()
                            .format('DD/MM/YYYY')}
                        </td>
                        <td className="text-center">
                          <Button
                            size="sm"
                            variant="danger"
                            className="me-0"
                            type="button"
                            onClick={() => {
                              setShowDelete(true);
                              setSelectedObject(object);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '2px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="rgba(255,255,255,1)"
                              />
                            </svg>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

Table.propTypes = {
  data: PropTypes.array
};

export default IndexFiles;
