import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AppContext from 'context/Context';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import NavbarTop from '../navbar/top/NavbarTop';
import NavbarVertical from '../navbar/vertical/NavbarVertical';
import Footer from '../footer/Footer';
import RequireAuthCustomer from '../../../middlewares/RequireAuthCustomer';
import { toast } from 'react-toastify';
import { ShowUser } from '../../../services/UserService';

const CustomerLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  const navigate = useNavigate();

  const {
    config: { isFluid }
  } = useContext(AppContext);

  useEffect(() => {
    RequireAuthCustomer(navigate);
    const fetchUser = async () => {
      const responseObjects = await ShowUser('x');
      if (responseObjects.success === true) {
        localStorage.setItem(
          'userLoadIzi',
          JSON.stringify(responseObjects.data)
        );
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchUser();
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <NavbarVertical />
      <ProductProvider>
        <div className={classNames('content', { 'pb-0': isKanban })}>
          <NavbarTop />
          {/*------ Main Routes ------*/}
          <Outlet />
          <Footer />
        </div>
      </ProductProvider>
    </div>
  );
};

export default CustomerLayout;
