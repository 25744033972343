import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import is from 'is_js';

import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import AppContext from 'context/Context';

import AuthSimpleLayoutCiarus from '../components/loadizi/layouts/AuthSimpleLayout';
import LoginCiarus from '../components/loadizi/pages/authentication/Login';
import ForgetPasswordCiarus from '../components/loadizi/pages/authentication/ForgetPassword';
import PasswordResetCiarus from '../components/loadizi/pages/authentication/PasswordReset';
import AdminLayout from '../components/loadizi/layouts/AdminLayout';
import IndexCompanies from '../components/loadizi/admin/companies';
import ManageAdmins from '../components/loadizi/admin/users/admins/manage';
import IndexAdmins from '../components/loadizi/admin/users/admins/indexAdmins';
import CustomerLayout from '../components/loadizi/layouts/CustomerLayout';
import IndexFiles from '../components/loadizi/customer/files';
import RegisterLoadIzi from '../components/loadizi/pages/authentication/Register';
import CheckoutLoadizi from '../components/loadizi/customer/checkout';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <>
      <Routes>
        <Route element={<AuthSimpleLayoutCiarus />}>
          <Route path="/" element={<LoginCiarus />} />
          <Route path="login" element={<LoginCiarus />} />
          <Route path="register" element={<RegisterLoadIzi />} />
          <Route path="forgot-password" element={<ForgetPasswordCiarus />} />
          <Route path="reset-password" element={<PasswordResetCiarus />} />
        </Route>
        <Route element={<AdminLayout />}>
          <Route
            path="/administration/entreprises"
            element={<IndexCompanies />}
          />

          <Route path="/administration/admins" element={<IndexAdmins />} />
          <Route
            path="/administration/admins/ajouter"
            element={<ManageAdmins />}
          />
          <Route
            path="/administration/admins/:id/modifier"
            element={<ManageAdmins />}
          />
        </Route>
        <Route element={<CustomerLayout />}>
          <Route path="/customers/dashboard" element={<IndexFiles />} />
          <Route path="/customers/checkout" element={<CheckoutLoadizi />} />
        </Route>
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
