import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Footer = () => (
  <footer className="footer">
    <Row className="justify-content-between fs--1 mt-4 mb-3">
      <Col sm="auto">
        <p className="mb-0 text-600">
          {new Date().getFullYear()} &copy; Loadizi - Un service proposé par{' '}
          <a href={'https://hostay.fr'}>Hostay</a> - Connecté en tant que{' '}
          {JSON.parse(localStorage.getItem('userLoadIzi'))?.email} <br />
          Code du compte Loadizi :{' '}
          {JSON.parse(localStorage.getItem('userLoadIzi'))?.company?.uuid}
        </p>
      </Col>
    </Row>
  </footer>
);

export default Footer;
