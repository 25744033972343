export default function RedirectIfAuthenticated(navigate) {
  if (
    localStorage.getItem('accessTokenLoadIzi') &&
    localStorage.getItem('userLoadIzi')
  ) {
    const user = JSON.parse(localStorage.getItem('userLoadIzi'));
    console.log(user);
    switch (user.role_id) {
      case 1:
        navigate('/administration/entreprises');
        break;
      case 2:
        navigate('/customers/dashboard');
        break;
    }
  }
}
