import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  OverlayTrigger,
  Tooltip,
  Spinner
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import * as Moment from 'moment/moment';
import SoftBadge from '../../../common/SoftBadge';
import { IndexCompany } from '../../../../services/CompanyService';
import ManageCompany from './manageCompany';
import ManageCompanyUsers from './manageCompanyUsers';

const IndexCompanies = () => {
  const [tablePageIndex, setTablePageIndex] = useState(0);
  const [trigger, setTrigger] = useState(Date.now());
  const [initialApiObjects, setInitialApiObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [showManageUsers, setShowManageUsers] = useState(false);
  const [showManageCompany, setShowManageCompany] = useState(false);

  const getStripeUrl = () => {
    if (process.env.NODE_ENV === 'production') {
      return 'https://dashboard.stripe.com';
    } else {
      return 'https://dashboard.stripe.com/test';
    }
  };

  const transformObjects = objects => {
    let transformedApiObjects = [];
    objects.map(obj => {
      transformedApiObjects.push({
        name: (
          <>
            {obj.active === 1 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="15"
                height="15"
                style={{
                  position: 'relative',
                  bottom: '1px',
                  marginRight: '5px'
                }}
              >
                <path
                  d="M15.874 13C15.4299 14.7252 13.8638 16 12 16C10.1362 16 8.57006 14.7252 8.12602 13H3V11H8.12602C8.57006 9.27477 10.1362 8 12 8C13.8638 8 15.4299 9.27477 15.874 11H21V13H15.874Z"
                  fill="rgba(150,218,183,1)"
                ></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="15"
                height="15"
                style={{
                  position: 'relative',
                  bottom: '1px',
                  marginRight: '5px'
                }}
              >
                <path
                  d="M15.874 13C15.4299 14.7252 13.8638 16 12 16C10.1362 16 8.57006 14.7252 8.12602 13H3V11H8.12602C8.57006 9.27477 10.1362 8 12 8C13.8638 8 15.4299 9.27477 15.874 11H21V13H15.874Z"
                  fill="rgba(234,113,46,1)"
                ></path>
              </svg>
            )}
            {obj.name}
          </>
        ),
        subscription: (
          <>
            {obj.subscription_type && obj.stripe_subscription_id ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${getStripeUrl()}/subscriptions/${
                  obj.stripe_subscription_id
                }`}
              >
                {obj.subscription_type}
              </a>
            ) : obj.subscription_type ? (
              <span>{obj.subscription_type}</span>
            ) : (
              () => null
            )}
            {obj.stripe_subscription_status && (
              <span className="d-block">
                {obj.stripe_subscription_status === 'active' ? (
                  <SoftBadge bg="success" className="ms-0">
                    {obj.stripe_subscription_status}
                  </SoftBadge>
                ) : (
                  <SoftBadge bg="danger" className="ms-0">
                    {obj.stripe_subscription_status}
                  </SoftBadge>
                )}
              </span>
            )}
          </>
        ),
        users: (
          <>
            {obj.users && (
              <span
                onClick={() => {
                  setSelectedObject(obj);
                  setShowManageUsers(true);
                }}
              >
                <SoftBadge bg="info" className="me-2 cursor-pointer">
                  {obj.users.length}
                </SoftBadge>
              </span>
            )}
          </>
        ),
        created_at: (
          <>
            {obj.created_at && (
              <Fragment>
                {Moment(obj.created_at).local().format('DD/MM/YYYY')}
              </Fragment>
            )}
          </>
        ),
        actions: (
          <>
            <OverlayTrigger
              overlay={<Tooltip id="overlay-trigger-example">Modifier</Tooltip>}
            >
              <Button
                size="sm"
                variant="primary"
                className="me-2 admin-primary"
                type="button"
                onClick={() => {
                  setSelectedObject(obj);
                  setShowManageCompany(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="14"
                  height="14"
                  style={{
                    marginBottom: '2px'
                  }}
                >
                  <path
                    d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
              </Button>
            </OverlayTrigger>
          </>
        )
      });
    });
    setObjects(transformedApiObjects);
  };

  useEffect(() => {
    const fetchObjects = async () => {
      setIsLoading(true);
      const responseObjects = await IndexCompany();
      if (selectedObject && selectedObject.id) {
        setSelectedObject(
          responseObjects.data.find(obj => obj.id === selectedObject.id)
        );
      }
      if (responseObjects.success === true) {
        setInitialApiObjects(responseObjects.data);
        transformObjects(responseObjects.data);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    fetchObjects();
  }, [trigger]);
  return (
    <>
      {showManageCompany && (
        <ManageCompany
          showManageCompany={showManageCompany}
          setShowManageCompany={setShowManageCompany}
          setparentTrigger={setTrigger}
          object={selectedObject}
          setObject={setSelectedObject}
        />
      )}
      {showManageUsers && (
        <ManageCompanyUsers
          showManageUsers={showManageUsers}
          setShowManageUsers={setShowManageUsers}
          object={selectedObject}
          setparentTrigger={setTrigger}
        />
      )}
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="text-primary admin-title mb-0">
                Gestion des clients
              </h3>
            </Col>
            <Col className="text-end" lg={6}>
              <Button
                onClick={() => {
                  setShowManageCompany(true);
                }}
                variant="primary"
                size="sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Ajouter
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {initialApiObjects.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="">
            <Row>
              {objects && objects.length > 0 && (
                <Fragment>
                  <Col xs={12} className="mt-3">
                    <Table
                      data={objects}
                      tablePageIndex={tablePageIndex}
                      setTablePageIndex={setTablePageIndex}
                    />
                  </Col>
                </Fragment>
              )}
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

function Table({ data, tablePageIndex, setTablePageIndex }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'Nom'
      },
      {
        accessor: 'subscription',
        Header: 'Souscription'
      },
      {
        accessor: 'users',
        Header: 'Utilisateurs'
      },
      {
        accessor: 'created_at',
        Header: 'Date de création'
      },
      {
        accessor: 'actions',
        Header: 'Action'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: tablePageIndex, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTablePageIndex(0);
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTablePageIndex(pageIndex - 1);
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTablePageIndex(pageIndex + 1);
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            setTablePageIndex(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  tablePageIndex: PropTypes.number,
  setTablePageIndex: PropTypes.func
};

export default IndexCompanies;
