import React, { useEffect, useState } from 'react';
import { Alert, Card, Col, Row, Button } from 'react-bootstrap';
import IconAlert from '../../common/IconAlert';
import SoftBadge from '../../common/SoftBadge';
import { useNavigate } from 'react-router';
import { IndexFileCount } from '../../../services/SharedFileService';
import { toast } from 'react-toastify';
const CheckoutLoadizi = () => {
  const navigate = useNavigate();
  const [filecount, setfilecount] = useState(0);
  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexFileCount();
      if (responseObjects.success === true) {
        setfilecount(responseObjects.data);
      }
    };
    fetchObjects();
  }, []);
  return (
    <Card>
      <Card.Body className="overflow-hidden px-lg-3 pt-5">
        <Row className="justify-content-center align-items-center">
          <Col xs={4}>
            <Button
              onClick={() => {
                navigate('/customers/dashboard');
              }}
              variant="falcon-default"
              size="sm"
              className="ms-0"
              style={{ position: 'relative', top: '-30px' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="12"
                height="12"
              >
                <path
                  d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z"
                  fill="rgba(52,64,80,1)"
                ></path>
              </svg>
              Mes fichiers
            </Button>
          </Col>
          <Col xs={4} className="text-center">
            <SoftBadge pill bg="warning" className="me-2 text-badge">
              Votre essai gratuit expire
            </SoftBadge>
          </Col>
          <Col xs={4} className="text-end">
            {''}
          </Col>
          <Col xs={8} className="text-center">
            <h3 className="mb-2 mt-4">
              Passez à la vitesse supérieure pour ne pas perdre vos{' '}
              {filecount > 1 ? filecount : ''} fichiers ! <br />
            </h3>
          </Col>
          <Col xs={8} className="mt-3">
            <IconAlert variant={'success'}>
              <p className="mb-0">
                <b>
                  🎁 OFFRE DU MOMENT : -50% pendant 3 mois avec le code promo{' '}
                  <span
                    style={{
                      backgroundColor: '#FFFFFF',
                      padding: '1px 3px',
                      border: '1px dashed #000000',
                      cursor: 'pointer'
                    }}
                  >
                    LOADIZI50
                  </span>
                </b>{' '}
                sur les offres Medium 10Go et Premium 50Go.
              </p>
            </IconAlert>
          </Col>
          <Col xs={8}>
            <Alert variant="secondary">
              <b>Important !</b> Lors de votre souscription, indiquez
              l'identifiant unique de votre compte Loadizi :{' '}
              <span
                style={{
                  backgroundColor: '#FFFFFF',
                  padding: '1px 3px',
                  border: '1px dashed #000000',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    JSON.parse(localStorage.getItem('userLoadIzi')).company
                      ?.uuid
                  );
                  toast('Identifiant unique copié dans le presse-papier');
                }}
              >
                {JSON.parse(localStorage.getItem('userLoadIzi')).company?.uuid}
              </span>
            </Alert>
          </Col>
          <Col xs={12} className="text-center">
            <stripe-pricing-table
              pricing-table-id="prctbl_1NbL6xKFmrkeS0C91YMQWIFI"
              publishable-key="pk_live_51Na2VcKFmrkeS0C9E1Pq8Tx3hMcW0fMOBtxxNw1INjgRVVZfP98cqjJyShMJ6Y4FwbXwWc6aBvQ9pMj4HvDaWTGz00o51E0UcT"
            ></stripe-pricing-table>
            <p>
              <small>
                <i>Tarifs HT</i>
              </small>
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CheckoutLoadizi;
