import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RegisterUserService } from '../../../../services/AuthService';
import { Button, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormAlert from '../../commons/FormAlert';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import logo from '../../../../assets/img/logo-loadizi-dark.png';

const RegisterLoadIzi = () => (
  <>
    <div className="d-block text-center">
      <img
        className="mb-4 cursor-pointer"
        src={logo}
        alt="Logo"
        width={250}
        onClick={() => {
          window.location.href = 'https://hostay.fr/landing-loadizi-transfert/';
        }}
      />
    </div>
    <h5 className="text-center mb-4">
      Essayez Loadizi gratuitement pendant 14 jours, sans engagement !
    </h5>
    <RegisterForm />
  </>
);

const RegisterForm = ({ hasLabel }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password_confirm: '',
    accept_terms: false,
    'g-recaptcha-response': false
  });

  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const ReComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const handleSubmit = async () => {
      try {
        setIsLoading(true);
        const token = await executeRecaptcha('check_register_form_loadizi');
        let formDataSubmit = {
          ...formData,
          'g-recaptcha-response': token
        };
        const response = await RegisterUserService(formDataSubmit);
        setIsLoading(false);
        setErrorForm(response.validator);
        if (
          localStorage.getItem('accessTokenLoadIzi') &&
          localStorage.getItem('userLoadIzi')
        ) {
          window.location.href = 'https://hostay.fr/loadizi/merci/';
          //RedirectIfAuthenticated(navigate);
        } else if (!response.validator) {
          setErrorForm(response.message);
          setIsLoading(false);
        } else {
          toast('Une erreur est survenue, veuillez réessayer plus tard');
        }
      } catch (err) {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    return (
      <div>
        <GoogleReCaptcha />
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={
            !formData.name ||
            !formData.email ||
            !formData.password ||
            !formData.password_confirm ||
            formData.password !== formData.password_confirm ||
            !formData.accept_terms ||
            isLoading
          }
          onClick={handleSubmit}
        >
          Créer mon compte
        </Button>
        <p className="text-center mb-0 mt-2">
          <small>
            <Link to="/login">J'ai déjà un compte</Link>
          </small>
        </p>
      </div>
    );
  };

  const handleFieldChange = e => {
    if (e.target.name === 'accept_terms') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked ? 1 : 0
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  return (
    <Form>
      {errorForm && <FormAlert variant="danger" data={errorForm} />}

      <Form.Group className="mb-3">
        <Form.Label>Nom / Entreprise</Form.Label>
        <Form.Control
          placeholder={!hasLabel ? 'Jean Durant' : ''}
          value={formData.name}
          name="name"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Adresse email</Form.Label>
        <Form.Control
          placeholder={!hasLabel ? 'monmail@gmail.com' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Row>
        <Form.Group as={Col} lg={6} className="mb-3 pe-lg-1">
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control
            placeholder={!hasLabel ? '********' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
        <Form.Group as={Col} lg={6} className="mb-3 ps-lg-1">
          <Form.Label>Confirmation du mot de passe</Form.Label>
          <Form.Control
            placeholder={!hasLabel ? '********' : ''}
            value={formData.password_confirm}
            name="password_confirm"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="accept_terms"
            checked={formData.accept_terms}
            onChange={handleFieldChange}
          />
          <Form.Check.Label className="form-label">
            En créant mon compte, j'accepte les{' '}
            <a
              href="https://hostay.fr/wp-content/uploads/2023/08/CGV-Loadizi-01-08-2023.pdf"
              target="_blank"
              rel="noreferrer"
            >
              conditions générales de vente
            </a>{' '}
            et{' '}
            <a
              href="https://hostay.fr/politique-de-confidentialite/"
              target="_blank"
              rel="noreferrer"
            >
              la politique de confidentialité
            </a>{' '}
            de Loadizi
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <Form.Group>
        <GoogleReCaptchaProvider
          reCaptchaKey="6LelbU0nAAAAAMQNc7GB-7W7_Hn4uTIBUgFvsKnq"
          language="fr-FR"
        >
          <ReComponent />
        </GoogleReCaptchaProvider>
      </Form.Group>
    </Form>
  );
};

RegisterForm.propTypes = {
  hasLabel: PropTypes.bool
};

RegisterLoadIzi.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

RegisterLoadIzi.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default RegisterLoadIzi;
