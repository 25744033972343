import React from 'react';
import NavbarDropdown from './NavbarDropdown';
import { Button, Col, Nav, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavbarTopDropDownMenus = () => {
  const navigateToHome = anchor => {
    if (window.location.pathname === '/') {
      document.getElementById(anchor).scrollIntoView();
    } else {
      window.location.href = `/#${anchor}`;
    }
    document.getElementsByClassName('navbar-toggler')[0].click();
  };
  return (
    <>
      <Nav.Item>
        <Nav.Link onClick={() => navigateToHome('groupement-presentation')}>
          Accueil
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link onClick={() => navigateToHome('adherents')}>Réseau</Nav.Link>
      </Nav.Item>

      {/*<Nav.Item>
        <Nav.Link onClick={() => navigateToHome('reseau')}>Réseau</Nav.Link>
      </Nav.Item>*/}

      <Nav.Item>
        <Nav.Link onClick={() => navigateToHome('metiers')}>Métiers</Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link onClick={() => (window.location.href = '/actualites')}>
          Actualités
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link onClick={() => navigateToHome('partenaires')}>
          Partenaires
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link onClick={() => (window.location.href = '/vis-ma-route')}>
          Vis ma route
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link onClick={() => navigateToHome('contact')}>Contact</Nav.Link>
      </Nav.Item>

      <Nav.Item className="big-menu-show">
        <Nav.Link onClick={() => (window.location.href = '/logcoop')}>
          Logcoop
        </Nav.Link>
      </Nav.Item>

      <Nav.Item className="big-menu-show">
        <Nav.Link
          onClick={() => (window.location.href = '/le-transport-recrute')}
        >
          Le Transport Recrute
        </Nav.Link>
      </Nav.Item>

      <Nav.Item className="big-menu-show">
        <Nav.Link
          onClick={() =>
            (window.location.href = '/le-monde-du-transport-reuni')
          }
        >
          LMTR
        </Nav.Link>
      </Nav.Item>

      <span className="big-menu-hide">
        <NavbarDropdown title="+ Plus">
          <Row>
            <Col md={12}>
              <Nav className="flex-column">
                <Nav.Link
                  as={Link}
                  className="fw-medium"
                  to={'#'}
                  onClick={() => (window.location.href = '/logcoop')}
                >
                  Logcoop
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  className="fw-medium"
                  to={'#'}
                  onClick={() =>
                    (window.location.href = '/le-transport-recrute')
                  }
                >
                  Le Transport Recrute
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  className="fw-medium"
                  to={'#'}
                  onClick={() =>
                    (window.location.href = '/le-monde-du-transport-reuni')
                  }
                >
                  Le Monde du Transport Réuni
                </Nav.Link>
              </Nav>
            </Col>
          </Row>
        </NavbarDropdown>
      </span>

      {/*<NavDropdown
        className="menu-red"
        title={<FontAwesomeIcon icon="search" />}
        align="end"
      ></NavDropdown>*/}

      {(!localStorage.getItem('accessTokenLoadIzi') ||
        !localStorage.getItem('userLoadIzi')) && (
        <Nav.Item>
          <Nav.Link
            onClick={() => (window.location.href = '/login')}
            className="menu-red"
          >
            <span className="d-none d-lg-inline">
              <FontAwesomeIcon
                style={{ color: '#e52f12' }}
                icon="lock"
                id="documentationTooltip"
              />
            </span>
            <span className="d-inline d-lg-none">
              <Button variant="primary" className="btn-login" size="xs">
                <FontAwesomeIcon icon="lock" id="documentationTooltip" /> Me
                connecter
              </Button>{' '}
            </span>
          </Nav.Link>
        </Nav.Item>
      )}
    </>
  );
};

export default NavbarTopDropDownMenus;
