import HttpService from './HttpService';

const http = new HttpService();

export async function LoginUserService(credentials) {
  let url = 'login';
  return http
    .postData(credentials, url)
    .then(data => {
      if ('token' in data) {
        localStorage.setItem('accessTokenLoadIzi', data.token);
        localStorage.setItem('userLoadIzi', JSON.stringify(data.data));
      }
      return data;
    })
    .catch(error => {
      return error;
    });
}

export async function RegisterUserService(formData) {
  let url = 'register';
  return http
    .postData(formData, url)
    .then(data => {
      if ('token' in data) {
        console.log('okidoki');
        localStorage.setItem('accessTokenLoadIzi', data.token);
        localStorage.setItem('userLoadIzi', JSON.stringify(data.data));
      }
      return data;
    })
    .catch(error => {
      return error;
    });
}

export const LogOutUserService = () => {
  const http = new HttpService();
  let url = 'logout';
  const tokenId = 'accessToken';
  return http.postData([], url, tokenId).then(() => {
    localStorage.removeItem('accessTokenLoadIzi');
    localStorage.removeItem('userLoadIzi');
    window.location.href = '/login';
  });
};

export async function ForgotPasswordEmailService(formData) {
  let url = 'password/email';
  return http
    .postData(formData, url)
    .then(data => {
      return data;
    })
    .catch(error => {
      return error;
    });
}

export async function ForgotPasswordResetService(credentials) {
  let url = 'password/reset';
  return http
    .postData(credentials, url)
    .then(data => {
      return data;
    })
    .catch(error => {
      return error;
    });
}
